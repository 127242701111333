const ReviewStars = ({ stars, color }) => {
    if(!color) color = '#ffca00';
    if (stars > 4.5) {
        return (
        <div class="d-flex" style={{color:color}}>
            <i class="bi bi-star-fill"></i>
            <i class="bi bi-star-fill"></i>
            <i class="bi bi-star-fill"></i>
            <i class="bi bi-star-fill"></i>
            <i class="bi bi-star-fill"></i>
        </div>
        );
    } else if (stars > 4) {
        return <div class="d-flex" style={{color:color}}>
            <i class="bi bi-star-fill"></i>
            <i class="bi bi-star-fill"></i>
            <i class="bi bi-star-fill"></i>
            <i class="bi bi-star-fill"></i>
            <i class="bi bi-star-half"></i>
        </div>;
    } else if (stars > 3.5) {
        return <div class="d-flex" style={{color:color}}>
            <i class="bi bi-star-fill"></i>
            <i class="bi bi-star-fill"></i>
            <i class="bi bi-star-fill"></i>
            <i class="bi bi-star-fill"></i>
            <i class="bi bi-star"></i>
        </div>;
    } else if (stars > 3) {
        return <div class="d-flex" style={{color:color}}>
            <i class="bi bi-star-fill"></i>
            <i class="bi bi-star-fill"></i>
            <i class="bi bi-star-fill"></i>
            <i class="bi bi-star-half"></i>
            <i class="bi bi-star"></i>
        </div>;
    } else if (stars > 2.5) {
        return<div class="d-flex" style={{color:color}}>
            <i class="bi bi-star-fill"></i>
            <i class="bi bi-star-fill"></i>
            <i class="bi bi-star-fill"></i>
            <i class="bi bi-star"></i>
            <i class="bi bi-star"></i>
        </div>;
    } else if (stars > 2) {
        return<div class="d-flex" style={{color:color}}>
            <i class="bi bi-star-fill"></i>
            <i class="bi bi-star-fill"></i>
            <i class="bi bi-star-half"></i>
            <i class="bi bi-star"></i>
            <i class="bi bi-star"></i>
        </div>;
    } else if (stars > 1.5) {
        return <div class="d-flex" style={{color:color}}>
            <i class="bi bi-star-fill"></i>
            <i class="bi bi-star-fill"></i>
            <i class="bi bi-star"></i>
            <i class="bi bi-star"></i>
            <i class="bi bi-star"></i>
        </div>;
    } else if (stars > 1) {
        return <div class="d-flex" style={{color:color}}>
            <i class="bi bi-star-fill"></i>
            <i class="bi bi-star-half"></i>
            <i class="bi bi-star"></i>
            <i class="bi bi-star"></i>
            <i class="bi bi-star"></i>
        </div>;
    } else if (stars > 0.5) {
        return <div class="d-flex" style={{color:color}}>
            <i class="bi bi-star-fill"></i>
            <i class="bi bi-star"></i>
            <i class="bi bi-star"></i>
            <i class="bi bi-star"></i>
            <i class="bi bi-star"></i>
        </div>;
    }
};

export default ReviewStars;
