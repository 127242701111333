import { useState } from "react"

const Calculator = (props) => {
    const [needed, setNeeded] = useState(1)
    const calculating = (event) => {
        setNeeded(Math.ceil((event.target.value) / props.calculator_value ))
    }

    return (<>
        <div className="custom-modal-title" > 
            <h4>Hoeveel {props.name} heb je nodig?</h4>
        </div>
        <div className="col-12 col-md-8 mx-auto calculator d-flex flex-column p-4 mb-2">
            {(props.calculator_warning_text) ?  <p className="mt-2 alert alert-warning justify-content-center"><i class="bi bi-exclamation-triangle me-3"></i>{props.calculator_warning_text}</p> : null }
            <p className="text-center lead mt-5"> Totaal aantal {props.name} nodig:   </p>
            <div className="badge bg-primary quantity-needed ">{needed}</div>
            <p className="text-center mt-5 me-3">Aantal {props.calculator_type_display} nodig:</p>
            <div className="d-flex justify-content-center">
                <input min="0" onChange={calculating} type="number" step={0.01} class="w-25 form-control me-2" id="calculator" placeholder={props.calculator_type_display} />
                <div onClick={ () => {props.setQuantity(needed);props.setModalContent(null);props.closeModal();props.setCalculator(null);}} className="btn btn-outline-primary" >Neem over</div>
            </div>
            <p className="alert border mt-4 text-center justify-content-center">Per {props.calculator_value} {props.calculator_type_display} heb je 1 {props.name} nodig.</p>
        </div>
    </>
    )
}

export default Calculator