import ReviewStars from '../../../UI/ReviewStars/ReviewStars'
import i18next from '../../../i18n'

const GroupCard = (props)  => {
    return (
        <div class={`category-card ${props.selected ? 'selected' : null}`}>
            <div class="category-card-inner d-flex flex-column">
                <a class="category-image image-wrapper" href={props.group.url} > 
                    { (props.group.image) ?
                        <img alt={props.group.name} title='Go to category' class="u-expanded-width lazyload" data-src={props.group.image} />
                        : ''
                    }
                </a>
                <div class="category-description">
                    <a href={props.group.url} > <h6 class="category-title">{props.group.name}</h6></a>
                </div>
            </div>
        </div>
    )

}

export default GroupCard;