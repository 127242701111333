import { euroFormatter } from "../../../functions/formatters"
import i18next from '../../../i18n'

const ProductPrice = ({totalDiscountPrice, totalPrice, quantity }) => {
    var vat_trans = i18next.t('vat_trans')
    var each_trans = i18next.t('each_trans')

    return <>
        <h4 id="price-tag" className="price-tag mb-2" >{ euroFormatter(totalDiscountPrice) }
        </h4>
        { (totalPrice != totalDiscountPrice ) ?
            <span id="old-price" className="old-price u-text u-text-6 me-2"  >
                <span>{euroFormatter(totalPrice)}</span>  
            </span>:
            ''
        }
        <span className="vat-message">{vat_trans}</span>
        {(quantity != 1) ? 
            <div  className="price-each u-text u-text-6 me-2"  >
                <span>({euroFormatter(totalDiscountPrice/quantity)} {each_trans})</span>  
            </div>
            :null
        }
    </>

}

export default ProductPrice