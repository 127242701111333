// import $ from "jquery"

// export const mrktrk = (event, custom_data = {}) => {
//     const url = '/mrk/pnt/'
//     const data = {'url':window.location.href, 'event':event, 'custom_data':JSON.stringify(custom_data)}
//     const method = "POST"
//     const response = $.ajax({
//         type: method,
//         url: url,
//         dataType: "json",
//         data: data, 
//     })
    
// }
export const addToCartEvent = (cart_line, clbFn) => {
    var total_price = 0
    console.log(cart_line)
    var contents = [
        {
            id: cart_line.id,
            item_price: cart_line.disc_price_in_vat.toString(),
            quantity: Math.round(cart_line.quantity),
        }
    ]
    cart_line.cart_lines.map( (line) => {
        contents.push( {
            id: line.id,
            item_price: line.disc_price_in_vat.toString(),
            quantity: Math.round(line.quantity),
        })
    })

    var custom_data = {
        'currency':'EUR',      
        'value':total_price.toString(),
        'contents':contents          
    }
    var x = new FormData();
    x.append( 'url',window.location.href)
    x.append( 'event','add_to_cart')
    x.append( 'custom_data', JSON.stringify(custom_data))

    var request_data = {
        url:'/mrk/pnt/',
        method:'POST',
        headers: {},
        data:x,
    }
    clbFn(request_data)
}