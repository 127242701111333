import { useEffect, useState } from "react";
import { euroFormatter } from "../../functions/formatters";

const ProductVariant = ({ child_line, changeCartline, setPrice }) => {
    var child_price = parseFloat(child_line.price_in_vat )
    var child_discount_price = parseFloat(child_line.disc_price_in_vat  )

    useEffect(() => {
        setPrice(child_price * child_line.quantity, child_discount_price * child_line.quantity)
    }, [child_line, changeCartline]) 

    return (<p className="text-muted small">
        <b>{ child_line.product_listing.product_object.category.name}</b>: 
        {child_line.product_listing.product_object.name } {(child_line.quantity != 1) ? " - " +  child_line.quantity + " stuks" : '' } { (child_price != 0) ? "(+" + euroFormatter(child_discount_price) +")" : ''}
        {(child_line.product_listing.product_object.category.form_type === "Quantity") ?
            <>
                <div 
                onClick={ () => changeCartline(child_line.id, Math.max(child_line.quantity-1, 0))}
                className="ms-3 my-1 py-0 btn btn-outline-light text-dark" 
                type="button" id="button-plus"> 
                    <i className="bi bi-dash"></i> 
                </div>
                <div
                onClick={ () => changeCartline(child_line.id, Math.max(child_line.quantity+1, 0))}
                className="py-0 btn btn-outline-light text-dark" 
                type="button" 
                id="button-minus"> 
                    <i className="bi bi-plus"></i> 
                </div>
            </>
            :null
        }
    </p> 
    )
}

export default ProductVariant; 