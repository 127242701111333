import CartForm from "./CartForm"
import i18next from '../../../i18n'
import { useCallback, useContext, useEffect, useState } from "react"
import CartContext from "../../../store/CartContext"

const AddedCartModal = ({cartLines, closeModal, quantity, addToCart, setCartLines, productPrices, getProductPrice, product_listing_objects, cart_line}) => {
    const [buttonText, setButtonText] = useState('')
    var added_to_cart_trans = i18next.t('added_to_cart_trans')
    var add_options = i18next.t('add_options')
    var cart_url = i18next.t('cart_url')

    const cart_context = useContext(CartContext)

    const addAdditionalItems = useCallback(async (event) => {
        if(cart_context.cartLines.filter( (x) => x !== undefined).length > 0){
            await cart_context.addToCart(event)   
        }
        window.location = cart_url
    }, [cart_context.cartLines]) 

    useEffect( () => {
        var go_to_cart_trans = (cart_context.cartLines.filter( (x) => x !== undefined).length > 0) ? i18next.t('add_others') + ' & ' + i18next.t('go_to_cart_trans').toLowerCase() : i18next.t('go_to_cart_trans') 
        setButtonText(go_to_cart_trans)
    },[cart_context.cartLines])

    return (
        <>
            <div className="custom-modal-title" > 
                <h4> {cart_line.quantity} x {cart_line.product_listing.product_object.name} {added_to_cart_trans} </h4>
            </div>
            <div className="custom-modal-body">
                {(product_listing_objects) ? 
                    <div className="modal-cart-form px-3">
                                <h5>{add_options}</h5>
                                <CartForm parent_cart_line_id={cart_line.id} showprice={false} addToCart={addToCart} productPrices={productPrices} getProductPrice={getProductPrice}  setCartLines={setCartLines} product_listing_objects={product_listing_objects} quantity={quantity} />
                    </div>
                    : null
                }
            </div>
            <div className="custom-modal-footer mx-auto px-3 col-12 col-md-6">
                <a onClick={addAdditionalItems} className="w-100 btn btn-primary"> {buttonText } </a> 
            </div>

        </>

    )
}

export default AddedCartModal