import { useCallback, useState } from "react";
import { AppSettings } from "../_custom/settings";
import { getCookie } from "../functions/getCookie";



var csrftoken = getCookie('csrftoken');

function csrfSafeMethod(method) {
    // these HTTP methods do not require CSRF protection
    return (/^(GET|HEAD|OPTIONS|TRACE)$/.test(method));
}


export const addAuthorization = async (requestObject) => {
    var body = JSON.stringify({'username':AppSettings.user, 'password':AppSettings.password})
    var token_response = await fetch( 
        AppSettings.base_url + '/' + AppSettings.portal_prefix + 'api-token-auth/' 
        , { 'method':'POST',
            'body':body, 
            'data':body, 
            'headers':{
                'Accept':'application/json',  
                'Content-Type':'application/json',  
                "X-Requested-With":'XMLHttpRequest',
                "X-CSRFToken":csrftoken
            }
        }
    )
    var token = await token_response.json()
    requestObject['headers']['Authorization'] = 'Token ' +  token.token
    return requestObject
}


const useHttp = (applyData) => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const sendRequest = useCallback(async (requestConfig, ) => {
        setIsLoading(true);
        setError(null);
        try{
            var requestObject = {
                 method: requestConfig.method ? requestConfig.method : 'GET'
                ,headers: requestConfig.headers ? requestConfig.headers : {}
                ,data: requestConfig.data ? requestConfig.data : null
                ,body: requestConfig.data ? requestConfig.data : null
            }
            if (!csrfSafeMethod(requestConfig.method)) { // && !this.crossDomain
                requestObject['headers']["X-CSRFToken"] = csrftoken;
                requestObject['headers']["X-Requested-With"] = 'XMLHttpRequest';
            } 
            if (!requestConfig.dataType || requestConfig.dataType==='form-data') {
                requestObject['headers']['Content-Type'] = 'multipart/form-data; boundary=----';
            } 
            else if(requestConfig.dataType==='json'){
                requestObject['headers']['Content-Type'] = 'application/json;';
            }
            var url = requestConfig.url
            const response = await fetch(url, requestObject)
            if(!response.ok){
                throw new Error('Something went wrong');
            }
            var data = {}

            if(requestConfig.json){
                data = await response.json()
            }
            else{
                data = await response.text()
            }
            applyData(data)
        } catch(err){
            console.log('error: ' + err)
            setError(err.message || 'Something went wrong!')
        }
        setIsLoading(false)
    }, [applyData])

    return {
         isLoading
        ,error
        ,sendRequest
    }

}

export default useHttp