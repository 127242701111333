import { useCallback, useEffect, useRef, useState } from "react"
import useForm from "../../hooks/use-form"
import useHttp from "../../hooks/use-http"
import $ from "jquery"
import Steps from "./Steps";
import i18next from '../../i18n'


const OrderForm = (props) => {
    const ref = useRef();
    const [formHTML, setFormHTML] = useState()
    const [formUrl, setFormUrl] = useState(null)
    const [currentStep, setCurrentStep] = useState(null)
    const [htmlSteps, setHtmlSteps] = useState(null)
    var confirm_trans = i18next.t('confirm_trans')
    var continue_cart_trans = i18next.t('continue_cart_trans')
    var saved_remark = i18next.t('saved_remark')
    var continue_cart_trans = i18next.t('continue_cart_trans')


    const steps = [
        {step:1,url:'/rest/order/checkout/', name: i18next.t('checkout_trans'), button_text: i18next.t('button_options_trans') },
        {step:2,url:'/rest/order/current/add_options/', name: i18next.t('options_trans'), button_text: i18next.t('button_confirm_trans')},
        {step:3,url:'/rest/order/current/confirm_order/', name: i18next.t('confirm_trans'), button_text: i18next.t('button_payment_trans'), 'hide_button':true},
        {step:4,url:'/rest/order/current/payment/', name: i18next.t('payment_trans'), button_text: i18next.t('button_confirm_trans')},
    ]
    
    useEffect( () => {
        const searchParams = new URLSearchParams(window.location.search);
        const current_step = steps.filter(
            (value) => value.step === parseInt(searchParams.get('step'))
        )
        if(current_step.length === 1){
            setCurrentStep(current_step[0])
        }
        else{
            setCurrentStep(steps[0])
        }
    
    }, [window.location])
    

    const formHandler = (form) =>{
        if(form.valid){
            var current_step
            if(currentStep.step === 3 ){
                current_step  = [currentStep]
            }
            else{
                current_step = steps.filter( (value) => value.step === (currentStep.step+1) )
            }
            setCurrentStep(current_step[0])
        }
        else{
            setFormHTML(form['form'])
        }

    }
    const [lineResponse, setLineReponse] = useState('')
    const handleLineRemark = (reponse) => {
        setLineReponse(<div className="my-2 p-1 mx-auto w-50 alert border-success" role="alert" ><i className="bi bi-check-lg text-succes me-4"></i> {saved_remark} </div>)
    }

    const {isLoading : formLoading, error : formError, sendRequest : submitFormData} = useForm(formHandler)
    const {isLoading : getFormLoading, error : getFormError, sendRequest : getFormData} = useHttp(setFormHTML)
    const {error : orderLineError, sendRequest : setLineRemark} = useHttp( handleLineRemark )

    const add_line_remark = (e) => {
        if (e.target.value.trim() != ''){
            var url = "/rest/order-line/<pk>/add_order_line_remark/" 
            url = url.replace('<pk>', e.target.dataset.id)
            var data = new FormData();
            data.append(e.target.name, e.target.value);
            setLineRemark({url:url, method: "POST", data:data})
        }
    } 
    useEffect( () => {
        if(currentStep){
            setFormUrl(currentStep.url)
            const html_steps = steps.map( (step) => {
                return <Steps fulfilled={step.step < currentStep.step} current={step.step === currentStep.step} step={step} setCurrentStep={setCurrentStep} />
            })
            setHtmlSteps(html_steps)
        }
    }, [currentStep])

    useEffect( () => {
        if(formUrl){
            console.log(window.location.search)
            getFormData({url:formUrl + window.location.search})
        }
    }, [formUrl])
    
    const submitForm = (event) => {
        event.preventDefault()
        console.log(ref.current)
        var formData = new FormData(ref.current)
        submitFormData({url:formUrl + window.location.search, method:'POST', data:formData})
    }

    
    const show_hide_parents = (e) =>{
        var parent_selectors = Array.from(document.getElementsByClassName("parent-selector"))
        parent_selectors.forEach( (element) =>{
            const parent_element = document.getElementById('wrapper-'+element.dataset.select_parent)
            if(parent_element){
                if (element.checked) {  
                    parent_element.classList.remove("d-none");
                }
                else{
                    parent_element.classList.add("d-none");
                }
            }
        })
    }
    const toggle_off_option = (e) => {
        var prev_element = e.target.previousElementSibling 
        prev_element.disabled = e.target.checked
    }

    useEffect( () => {
        var company = $('input[name="company"]')
        var create_account = $('input[name="create_account"]')
        var use_same_delivery_address = $('input[name="use_same_delivery_address"]')
        var order_line_remark = Array.from(document.getElementsByClassName('order-line-remark'))
        var submit_discount_coupon_text = document.getElementById('submit_discount_coupon_text')
        var parent_selectors = Array.from(document.getElementsByClassName("parent-selector"))
        var boolean_selectors = Array.from(document.getElementsByClassName("form-check-input"))
            
        parent_selectors.forEach( (element) =>{
            element.addEventListener('change', show_hide_parents)
        })
        show_hide_parents()
        boolean_selectors.forEach( (element) =>{
            element.addEventListener('change', toggle_off_option)
        })

        if(submit_discount_coupon_text){
            submit_discount_coupon_text.addEventListener('click', submitForm)

        }
        if(order_line_remark.length > 0){
            order_line_remark.forEach( (el) => {
                el.addEventListener('change', add_line_remark)
            })
        }


        if(company.val()){
            $('#vat_number').addClass('show')
        }
    
        $('input[name="company"]').change(function(){
            if(company.val()){
                $('#vat_number').addClass('show')
            }
            else{
                $('#vat_number').removeClass('show')
            }
        })
    
        if(create_account.is(':checked')){
            $('#password').addClass('show')
        }
    
        $('input[name="create_account"]').change(function(){
            if(create_account.is(':checked')){
                $('#password').addClass('show')
            }
            else{
                $('#password').removeClass('show')
            }
        })
        
        $('input[name="use_same_delivery_address"]').change(function(){
            if(use_same_delivery_address.is(':checked')){
                $('#delivery-form').removeClass('show')
            }
            else{
                $('#delivery-form').addClass('show')
            }
        })
    
        $("#delivery_address-new").click(function(){
            $('#new-delivery-address').addClass('show')
        })
        
        $(".delivery_address-existing").click(function(){
            $('#new-delivery-address').removeClass('show')
        })
    
        $("#billing_address-new").click(function(){
            $('#new-billing-address').addClass('show')
        })
        
        $(".billing_address-existing").click(function(){
            $('#new-billing-address').removeClass('show')
        })
    }, [formHTML])

    return(
            <>
                <div className="col-10 col-lg-8 offset-1 offset-lg-2 d-flex align-items-start">
                    {htmlSteps}
                </div>
                {(orderLineError ) ? <div className="my-2 p-1 mx-auto w-50 alert border-danger text-danger" role="alert"><i className="bi bi-exclamation-triangle me-4"></i> Er ging iets mis met met het opslaan van de opmerking</div> : lineResponse }

                {(formLoading || getFormLoading ) ? 
                    <div className="col-lg-8 offset-2">
                        <div className="placeholder-glow d-flex flex-column justify-content-center align-items-center" style={{height:'500px'}}>
                            <span className="placeholder col-8 mb-3"></span>
                            <span className="placeholder col-5 mb-3"></span>
                            <span className="placeholder col-7 mb-3"></span>
                            <span className="placeholder col-6 mb-3"></span>
                            <span className="placeholder col-7 mb-3"></span>
                            <span className="placeholder col-6 mb-3"></span>
                            <span className="placeholder col-5 mb-3"></span>
                        </div>
                    </div>
                    : 
                    (formError)? 
                    <div className="col-lg-8 offset-2">
                        <h6> 500 - Something went wrong on our side</h6>
                    </div>

                    :
                    <form ref={ref} onSubmit={submitForm} className="checkout-form" >
                        <div 
                                className={`checkout-form`}
                                dangerouslySetInnerHTML={{
                                    __html: formHTML
                                }}
                                />
                        {(currentStep) ? 
                            (currentStep.hide_button)? 
                                null :
                                <button className="w-auto mx-auto mb-2  btn btn-primary d-block " type="submit" value="submit">{currentStep.button_text}</button>
                            :null
                        }
                    </form>
                }
            </>
    )
}

export default OrderForm;