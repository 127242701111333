import ImageGallery from "react-image-gallery";


const ProductImages = ({product}) => {
    const images = product.product_object.images.map( (image, index) => {
        return {
            original: image.image,
            thumbnail : image.image_small

        }
    })

    return <ImageGallery items={images} />;


}

export default ProductImages