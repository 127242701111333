const ElementAddToCart = (props) => {

    return (
        <a className="btn btn-dark me-3" onClick={(e) => props.addOptionToCart()}>
            <i className="bi bi-plus-lg me-1 "></i>
            <i className="bi bi-cart"></i>
        </a>
    )
}

export default ElementAddToCart