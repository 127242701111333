import { useEffect, useState } from "react"
import CartLine from "./CartLine"
import { euroFormatter } from "../../functions/formatters"
import i18next from "i18next";

const Cart = () => {
    let [pageContext, setPageContext] = useState({} )
    let [cartLines, setCartLines] = useState([])
    let [cartTotal, setCartTotal] = useState({discount_price:0, price:0})

    const discount_trans = i18next.t('discount_trans')
    const subtotal_trans = i18next.t('subtotal_trans')
    const vat_note_trans = i18next.t('vat_note_trans')
    const product_trans = i18next.t('product_trans')
    const quantity_trans = i18next.t('quantity_trans')
    const price_trans = i18next.t('price_trans')

    useEffect(() => {  
        let text = document.getElementById('page-context').textContent  
        setPageContext( JSON.parse(text) )  
    }, [])  

    useEffect(() => {
        if(pageContext.cart_lines){
            const html_cart_lines = pageContext.cart_lines.map((cart_line) => {
                return <CartLine  key={cart_line.id} setCartTotal={setCartTotal} line={cart_line} />
            })
            setCartLines(html_cart_lines)
        }
    },[pageContext])


    return (

        <div className="">
            <div className="row pb-2 border-bottom cart-header text-muted small text-uppercase">
                <div className="col-8">
                    {product_trans}
                </div>
                <div className="col-2 text-center">
                    {quantity_trans}
                </div>
                <div className="col-2 text-center">
                    {price_trans}
                </div>
            </div>
            <div className="row cart-body">
                {cartLines}
            </div>
            <div class="mt-4 col-8 col-md-3 offset-2 offset-md-9">
				<div class="">
					<div class="card-body">
						{ (cartTotal.price - cartTotal.discount_price != 0) ?
                            <>
                                <dt>{discount_trans}:</dt>
                                <dd class="text-end">{ euroFormatter( cartTotal.price - cartTotal.discount_price)}</dd>
                            </>
                            : '' 
                        }
                        <dt>{subtotal_trans}:</dt>
						<dd class="text-end">{euroFormatter(cartTotal.discount_price)}</dd>
						<hr class="" />
						<small>
							<p class="small text-muted text-center mb-3">
							{vat_note_trans}
							</p>
						</small>
					</div> 
				</div> 
			</div> 
        </div>


    )

}

export default Cart;