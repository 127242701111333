import ReviewStars from '../../../UI/ReviewStars/ReviewStars'
import { dateFormatter } from '../../../functions/formatters'
import i18next from '../../../i18n'

const ProductDescription = ({product}) => {
    var nb_trans = i18next.t('nb_trans')

    return (
        <>
            <h1 class="product-detail-title" >{product.product_object.name}</h1>
            { (product.product_object.average_rating) ?
                <div class="d-flex align-items-center " >
                    <div class=" d-flex align-items-center  " >
                        <ReviewStars stars={product.product_object.average_rating} />
                    </div>
                    <a 
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseFour"
                        aria-expanded="false"
                        aria-controls="collapseFour"
                        href="#product_information"
                        class="col-md-6 ms-1 small text-nowrap text-dark text-decoration-underline"
                    >{product.product_object.reviews.length} reviews ({product.product_object.average_rating})
                    </a>
                </div>
                : null
            }

            { (product.product_object.delivery_date) ? 
                <small class="text-danger">{nb_trans}: {dateFormatter(product.product_object.delivery_date)} </small>
                : null
            }

            <p class="mt-2" 
                dangerouslySetInnerHTML={{__html:product.product_object.short_description}}
            />
        </>

    )
}

export default ProductDescription