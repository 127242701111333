import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import StoreApp from './Components/Store/StoreApp';
import Cart from './Components/Cart/Cart';
import OrderForm from './Components/OrderForm/OrderForm';

import './scss/base.scss';
import { ErrorBoundary } from "react-error-boundary";
import { getCookie } from './functions/getCookie';
import ProductDetail from './Components/ProductDetail/ProductDetail';


const fallback = <h6 class="text-center my-5 ">Helaas ging er iets fout bij ons. Neem aub contact met ons op.</h6>

const errorLogging = (error, info) => {
  var csrftoken = getCookie('csrftoken');
  var body = JSON.stringify(
      'JS ERROR: url: ' + window.location + '. error: '+ error.toString() + '. Component: '+ info.componentStack
  )
  const response = fetch('/server/error/', {
        method:'POST',
        body:body,
        headers:{
            'X-CSRFToken':csrftoken,
            'X-Requested-With':'XMLHttpRequest',
        }
    })
}

if(document.getElementById('britge-store')){
  const store_root = ReactDOM.createRoot(document.getElementById('britge-store'));
  store_root.render(
    <React.StrictMode>
      <ErrorBoundary fallback={fallback} onError={errorLogging} >
        <StoreApp />
      </ErrorBoundary>
    </React.StrictMode>
  );
}

if(document.getElementById('britge-product-form')){
  const product_form = ReactDOM.createRoot(document.getElementById('britge-product-form'));
  product_form.render(
    <React.StrictMode>
      <ErrorBoundary fallback={fallback} onError={errorLogging} >
          <ProductDetail />
      </ErrorBoundary>
    </React.StrictMode>
  );
}

if(document.getElementById('britge-cart')){
  const britge_cart = ReactDOM.createRoot(document.getElementById('britge-cart'));
  britge_cart.render(
    <React.StrictMode>
      <ErrorBoundary fallback={fallback} onError={errorLogging} >
        <Cart />
      </ErrorBoundary>
    </React.StrictMode>
  );
}

if(document.getElementById('britge-order-form')){
  const order_form = ReactDOM.createRoot(document.getElementById('britge-order-form'));
  order_form.render(
    <React.StrictMode>
      <ErrorBoundary fallback={fallback} onError={errorLogging} >
        <OrderForm />
      </ErrorBoundary>
    </React.StrictMode>
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
