import {createContext} from 'react'


const ModalContext = createContext({  
    isModalOpen : false,
    modalContent : null,
    setModalForm : () => {},
});

export default ModalContext;
