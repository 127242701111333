import ReviewStars from '../../../UI/ReviewStars/ReviewStars'
import i18next from '../../../i18n'

const ProductCard = (props)  => {
    var discount_html = ''
    if(props.product.discount_group.discount_label){
        discount_html = (
            <span class="discount-tag">
                {props.product.discount_group.discount_label}
            </span>
        )
    }
    var temp_unav_trans = i18next.t('temp_unav_trans')
    var from_trans =i18next.t('from_trans')
    var price = props.product.price_from_in_vat.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
    var discount_price = props.product.disc_price_from_in_vat.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
    return (
        <div class="product-card">
            <div class="product-card-inner my-2 mx-1  d-flex flex-column pt-1 pb-3">
                <a class="product-image" href={props.product.product_object.url} > 
                    <div class="product-tags" >
                        {discount_html}
                        {
                            (props.product.product_object.status.temporarily_unavailable) ?
                            <div class="product-unavailable bg-danger" >
                                {temp_unav_trans}
                            </div> : ''
                        }
                    </div>
                    <div class="image-wrapper w-100">
                        { (props.product.product_object.image_medium) ?
                            <div>
                                <img alt={props.product.product_object.name} class="main-image u-expanded-width lazyload  " data-image-width="1067" data-image-height="1600" data-src={props.product.product_object.image_medium} />
                                <img alt={props.product.product_object.name} class="hover-image u-expanded-width lazyload " data-image-width="1067" data-image-height="1600" data-src={props.product.product_object.image_hover_medium} />
                            </div>
                            : ''
                        }
                    </div>
                </a>
                <div class="product-description">
                    <a  href={props.product.product_object.url} > <h6 class="hover product-title">{props.product.product_object.name} {props.product.product_object__sequence}</h6></a>
                    <small class="u-small-text u-text u-text-variant u-text-2">{props.product.product_object.category.name}</small> 
                    <div class="flex-column flex-md-row d-flex ">
                        {(props.product.product_object.reviews.length > 0) ?
                            <>
                                <ReviewStars stars={props.product.product_object.average_rating} />
                                <small class="my-auto">
                                    {<span class="my-auto col-12 col-md-6 ms-1 text-nowrap text-dark">{props.product.product_object.reviews.length} reviews ({props.product.product_object.average_rating})</span> }
                                </small>
                            </>:
                            ''
                        }
                    </div>
                    <div class="product-price">
                        <span class="mt-3" >{from_trans}</span>
                        <div class="d-flex align-items-end">
                            <h4 class="price-tag mb-0 me-2">
                                €{discount_price}
                            </h4>
                            { ( price != discount_price ) ?
                                <span class="old-price" > €{price} </span>
                                : ''
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default ProductCard;