
const Radio = ({parent_cart_line_id, category, option, formChange }) => {
    const opts = {}
    if (option.product_object.status.is_temporarily_unavailable) opts['disabled'] = 'disabled';
    return (
        // <div className={`radio-card p-0 label-group`} data-parent={option.parent}  >
            <label className="radio-card label-group option btn d-flex flex-column" for={`option${option.id}`}>
            { (option.product_object.image_small) ?
                <img className="option-image" src={option.product_object.image_small}/> 
                : ''
            }
                <input 
                    {...opts}
                    data-parent_cart_line_id={parent_cart_line_id}
                    data-product={option.id} 
                    data-parent={option.parent_listing} 
                    data-price={option.price_in_vat}
                    data-discountprice={option.disc_price_in_vat}
                    data-quantity={option.quantity}
                    data-type="radio"
                    type="radio" 
                    defaultChecked={option.is_base}
                    className="product-form "
                    onChange={formChange}
                    name={option.category} 
                    id={`option${option.id}`} />  
                <span className="option-description">
                    <p class="option-name">{option.product_object.name}</p>
                    { (parseFloat(option.price) !== 0) ? <div>(+ €{option.price})</div> : null }
                </span>
            </label>
        // </div>
    );
  }   
  
  
  export default Radio;