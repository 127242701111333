import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import { getCookie } from "./functions/getCookie";


const resources = {
    de: {
      translation: {
        "cart_url": '/einkaufswagen/',
        "temp_unavailable_trans": "Welcome to React and react-i18next",
        'nb_trans':"Verfügbar ab",
        'temp_unav_trans': 'Vorübergehend nicht verfügbar',
        "from_trans": "Ab",
        "total_trans": "Gesamt",
        "confirm_trans": "Afronden",
        "continue_cart_trans": "Verder winkelen",
        "checkout_trans": "Bestellinformationen",
        "options_trans": "Bestelloptionen",
        "confirm_trans": "Bestellüberprüfung",
        "payment_trans": "Bestellzahlung",
        "button_checkout_trans": "Bestellinformationen",
        "button_options_trans": "Zu Optionen",
        "button_confirm_trans": "Kontrollieren",
        "button_payment_trans": "Zahlung",
        "configure_trans":'Konfigurieren',
        "add_trans":'Hinzufügen',
        'added_to_cart_trans':' Ihrem Warenkorb hinzugefügt',
        'go_to_cart_trans':'Einkaufswagen',
        'each_trans':'Pro Einheit',
        'keep_shopping_trans':'Weiter einkaufen',
        'vat_trans':'inklusive mehrwertsteuer',
        'categories_trans':'Kategorien',
        'change_product_trans':'Passen Sie zunächst die Produktzusammensetzung an, bevor Sie den Artikel erneut in den Warenkorb legen.',
        'discount_trans':'Rabat',
        'subtotal_trans':'Zwischensumme',
        'vat_note_trans':'Inklusive Mehrwertsteuer, zzgl. Versandkosten',
        'product_trans':'Produkt',
        'quantity_trans':'Menge',
        'price_trans':'Preis',
        'add_options': 'Optionen hinzufügen',
        'configure_trans':'Kompilieren',
        'add_others':'Optionen hinzufügen',
        'saved_remark':'Opmerking successvol opgeslagen',
        'calc_text_trans':'Wie viel brauche ich?',
      }
    },
    nl: {
      translation: {
        "cart_url": '/winkelwagen/',
        'nb_trans':'Leverbaar vanaf',
        'temp_unav_trans': 'Tijdelijk niet beschikbaar',
        "from_trans": "Vanaf",
        "total_trans": "Totaal",
        "confirm_trans": "Afronden",
        "continue_cart_trans": "Verder winkelen",
        "checkout_trans": "Informatie",
        "options_trans": "Opties",
        "confirm_trans": "Controle",
        "payment_trans": "Betalen",
        "button_checkout_trans": "Informatie",
        "button_options_trans": "Naar Afronden",
        "button_confirm_trans": "Naar Controle",
        "button_payment_trans": "Betalen",
        'each_trans':'Per stuk',
        "configure_trans":'Samenstellen',
        "add_trans":'Voeg toe',
        'added_to_cart_trans':' toegevoegd aan je winkelwagen',
        'go_to_cart_trans':' Naar winkelwagen',
        'keep_shopping_trans':'Verder winkelen',
        'vat_trans':'inclusief btw',
        'categories_trans':'Categoriën',
        'change_product_trans':'Pas eerst de productsamenstelling aan voordat je het item nog een keer in de winkelwagen stopt.',
        'discount_trans':'Korting',
        'subtotal_trans':'Subtotaal',
        'vat_note_trans':'Inclusief BTW, exclusief verzendkosten',
        'product_trans':'Product',
        'quantity_trans':'Aantal',
        'price_trans':'Prijs',
        'add_options': 'Voeg opties toe',
        'configure_trans':'Samenstellen',
        'add_others':'Voeg toe',
        'saved_remark':'Opmerking successvol opgeslagen',
        'calc_text_trans':'Hoeveel heb ik nodig?',
      }
    }
  };
  const language = getCookie('language-britge')
  i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: language,
    fallbackLng:'nl',
    interpolation: {
      escapeValue: false 
    }
  });

  export default i18n;