const Select = ({parent_cart_line_id, options, category, formChange }) => {
    var html_options = options.map( (option) => {    
        var opts = {}
        (option.product_object.tempUnavailable) ? opts['disabled'] = 'disabled' : '' 

        return (
            <option  
                {...opts}
                data-parent_cart_line_id={parent_cart_line_id}
                data-product={option.id} 
                data-parent={option.parent_listing} 
                data-price={option.price_in_vat}
                data-discountprice={option.disc_price_in_vat}
                data-quantity={option.quantity}
                data-type="select"
                onChange={formChange}
                id={`option${option.id} `}
                value={ option.id }
            >{option.product_name} { (parseFloat(option.price) !== 0) ? <span>(+ €{option.price}) </span>: null }
        </option>
        )
    })
    return {html_options};
  }   
  
  
  export default Select;