import ProductCell from "./ProductCell"
import QuantityCell from "./QuantityCell";
import PriceCell from "./PriceCell";
import { useCallback, useEffect, useState } from "react";


const CartLine = ({line, setCartTotal}) => {   
    console.log('cartline', line)
    let [cartLineTotal, setCartLineTotal] = useState({discount_price:0, price:0})
    const setPrice = useCallback( (price, discount_price) => {
        setCartLineTotal( (prev_price) => ( {discount_price: prev_price.discount_price + discount_price, price: prev_price.price + price}) )
        setCartTotal( (prev_price) => ( {discount_price: prev_price.discount_price + discount_price, price: prev_price.price + price} ) )
    }
    ,[])

    const changeCartline = async (id, quantity) => {
        const response = await fetch(
            '/rest/cart-line/'+id + '/cart_quantity_add/?quantity='+quantity,
        )
        window.location.reload()
    }

    return (
        <div class="row border-bottom">
            <div className="product-cell  col-12 col-md-8 py-3">
                <ProductCell setPrice={setPrice} line={line} cartLineTotal={cartLineTotal} changeCartline={changeCartline} />
            </div>
            <div className="quantity-cell  col-6 col-md-2  py-3  ">
                <QuantityCell changeCartline={changeCartline} line={line} />   
            </div>
            <div className="price-cell col-6 col-md-2  py-3">
                <PriceCell line={line} cartLineTotal={cartLineTotal} />
            </div>
        </div>

    )
}

export default CartLine 