const LoadingCard = () => {
    return(
        <div class="product-card" style={{height:'450px'}}>
            <div class="product-card-inner my-2 mx-1  d-flex flex-column pt-1 pb-3">
                <a class="product-image" > 
                    <span class="main-image placeholder u-expanded-width" style={{height:'300px'}} />
                </a>
                <div class="product-description placeholder-glow">
                    <span class="placeholder  mb-2 col-6"></span>

                    <div>
                        <small class="placeholder  mb-2 col-4"></small>
                    </div>
                    
                    <div class="product-price">
                        <span class="placeholder mb-2 col-2"></span>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default LoadingCard;