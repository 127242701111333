import ElementAddToCart from "./ElementAddToCart"
import Checkbox from "./Checkbox"
import Quantity from "./Quantity"
import Radio from "./Radio"
import Select from "./Select."

const FormElement = ({addToCart, parent_cart_line_id, form_options, category, formChange}) => {
    const form_type = form_options[0].form_type
    var element;
    if (form_type == 'Radio') {
        var options = form_options.map( (option) => {
            return <Radio parent_cart_line_id={parent_cart_line_id} option={option} formChange={formChange} />
            }
        )
        element = <>
            <div className="radio-options">{options}</div>
            {/* { (parent_cart_line_id) ? <ElementAddToCart addOptionToCart={addOptionToCart} /> :null}  */}
        </>
    }
    else if(form_type == 'Select'){ 
        element = <>
            <select name={category} id={category} class="product-form p-1 form-control" data-parent={category}>
                <Select parent_cart_line_id={parent_cart_line_id} options={form_options} formChange={formChange} />
            </select>
            {/* { (parent_cart_line_id) ? <ElementAddToCart addOptionToCart={addOptionToCart} /> :null}  */}
        </>
    }
    else if(form_type == 'Quantity'){   
        element = form_options.map( (option) => {
            return <>
                    <Quantity parent_cart_line_id={parent_cart_line_id} option={option} formChange={formChange} />
                    {/* { (parent_cart_line_id) ? <ElementAddToCart addOptionToCart={addOptionToCart} /> :null}  */}
                </> 
            }
        )
    }
    else if(form_type == "Checkbox"){   
        element = form_options.map( (option) => {
            return <div className="radio-options">
                <Checkbox parent_cart_line_id={parent_cart_line_id} option={option} formChange={formChange} />
                {/* { (parent_cart_line_id) ? <ElementAddToCart addOptionToCart={addOptionToCart} /> :null}  */}
            </div> 
            }
        )
    }
    else{
        return 
    }
    return <div className="category-options">
        <h6 className="option-category-title">{category}</h6>
        <div className="product-option-category">
                {element}
        </div>
    </div> 

}

export default FormElement