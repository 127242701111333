import { useEffect, useState } from "react";


const Checkbox = ({parent_cart_line_id, option, setData, formChange }) => {

  return (
        <div class="quantity-option-form-element">
            <label className="label-group radio-card option btn d-flex flex-column" for={`option${option.id}`}>
                {(option.product_object.image_small) ? <img className="variant-image" src={option.product_object.image_small} /> :<div className="variant-image"  /> }
                <input 
                    data-parent_cart_line_id={parent_cart_line_id}
                    data-product={option.id} 
                    data-parent={option.parent_listing} 
                    data-price={option.price_in_vat}
                    data-discountprice={option.disc_price_in_vat}
                    data-quantity={option.quantity}
                    data-type="checkbox"
                    type="checkbox" 
                    defaultChecked={option.is_base}
                    className="product-form "
                    onChange={formChange}
                    name={option.category} 
                    id={`option${option.id}`} />  
                <span className="option-description">
                    <p class="option-name">{option.product_object.name}</p>
                    { (parseFloat(option.price) !== 0) ? <span>+€{option.price}</span> : null }
                </span>
            </label>
        </div>
  );
};

export default Checkbox;

