import { useEffect, useState } from "react";
import { dateFormatter, euroFormatter } from "../../functions/formatters";
import ProductVariant from "./ProductVariant";

const ProductCell = ({ line, setPrice, cartLineTotal, changeCartline }) => {
    var delivery_date_trans = 'Leverdatum'
    const [cartLines, setCartLines] = useState([])
    var price = parseFloat(line.price_in_vat )
    var discount_price = parseFloat(line.disc_price_in_vat)

    useEffect(() => {
        var total_price = parseFloat(line.price_in_vat * line.quantity)
        var total_discount_price = parseFloat(line.disc_price_in_vat  * line.quantity)
        setPrice(total_price, total_discount_price)

        const child_lines = line.cart_lines.map((child_line) => <ProductVariant setPrice={setPrice} child_line={child_line} changeCartline={changeCartline} /> )
        setCartLines(child_lines)
    }, [line, setPrice]) 

    return (
        <div className="d-flex align-items-start">
            <div className="d-none d-sm-flex align-self-center">
                <a href={ line.product_listing.product_object.url }>
                    {(line.product_listing.product_object.image_small) ?  
                        <img 
                            src={line.product_listing.product_object.image_small} 
                            className="img-sm"/>
                        :
                        <img src="" className="img-sm"/>
                    }
                </a> 
            </div>
            <div className="ms-1 ms-md-4" >
                <a href={line.product_listing.product_object.url} className="product-title ">{line.product_listing.product_object.name}</a> ({euroFormatter(discount_price)})
                {cartLines}
                {(line.product_listing.product_object.delivery_date) ? <p class="ms-auto mt-auto text-danger small">{delivery_date_trans}: {dateFormatter(line.product_listing.product_object.delivery_date)} </p> : '' }
            </div>

        </div>

    )
}

export default ProductCell; 