import { useCallback, useContext, useEffect,  useState } from "react"
import { getCookie } from "../../../functions/getCookie"
import i18next from '../../../i18n'
import CartForm from "./CartForm"
import ProductDescription from "./ProductDescription"
import AddedCartModal from "./AddedCartModal"
import { addToCartEvent, mrktrk } from "../../../functions/mrktrk"
import Calculator from "../Calculator/Calculator"
import useHttp from "../../../hooks/use-http"
import CartContext from "../../../store/CartContext"
import ModalContext from "../../../store/ModalContext"



const ProductForm = ({product, closeModal, openModal, setModalContent}) => {
    const [calculator, setCalculator] = useState(null)  

    const cart_context = useContext(CartContext)
    const modal_context = useContext(ModalContext)

    var temp_unav_trans = i18next.t('temp_unav_trans')
    var add_trans = i18next.t('add_trans')
    var calc_text_trans = i18next.t('calc_text_trans')


    if(!product.product_object){
        return <div></div>
    }
    const showCalculator = () => {
        if(calculator === null){
            modal_context.openModal()
            modal_context.setModalContent(<Calculator setModalContent={modal_context.setModalContent} closeModal={modal_context.closeModal} setCalculator={setCalculator} setQuantity={cart_context.setQuantity} name={product.product_object.name} calculator_warning_text={product.product_object.calculator_warning_text} calculator_type_display={product.product_object.calculator_type_display} calculator_value={product.product_object.calculator_value} />)
        }
        else{
            modal_context.closeModal()
            modal_context.setModalContent(null)
        }
    }
    return(
        <div class="product-form-cell ">
            <ProductDescription product={product}/>
            { (product.product_object.status.temporarily_unavailable) ?
                <div class="">
                    <h5 > {temp_unav_trans} </h5>
                    <div disabled class="u-btn u-btn-submit u-button-style" >{add_trans}</div>
                </div>
                :
                <>
                    <CartForm product={product} productPrices={cart_context.productPrices} getProductPrice={cart_context.getProductPrice} showprice={true} addToCart={cart_context.addToCart} setCartLines={cart_context.setCartLines} product_listing_objects={product.product_listing_objects.filter( (el) => el.product_object.category.variant_place === '1')} quantity={cart_context.quantity}>
                        <div className="d-flex align-items-center mb-3">
                            <div class="btn-group btn-group-lg me-2" role="group" aria-label="Small button group">
                                <div 
                                    onClick={(e) => cart_context.setQuantity((prev) => (Math.max(prev - 1, 1)))}
                                    className="py-1 btn btn-outline-dark text-dark d-flex justify-content-center align-items-center" 
                                    type="button" id="button-plus"> 
                                    <i className="bi bi-dash"></i> 
                                </div>
                                <input 
                                    data-product={product.id} 
                                    data-price={product.price_in_vat}
                                    data-discountprice={product.disc_price_in_vat}
                                    data-type="quantity"  
                                    className="btn btn-outline-dark d-inline w-25 form-control" 
                                    type="number" 
                                    min={1}
                                    name={product.id} 
                                    onChange={e => cart_context.setQuantity(parseInt(e.target.value))}
                                    value={cart_context.quantity} />
                                <div 
                                    onClick={(e) => cart_context.setQuantity((prev) => (prev +1))}
                                    className="py-1 btn btn-outline-dark text-dark d-flex justify-content-center align-items-center" 
                                    type="button" 
                                    id="button-minus"> 
                                    <i className="bi bi-plus"></i> 
                                </div>
                            </div>
                            { (product.product_object.calculator_value && product.product_object.calculator_type_display) ?
                                <div className="btn btn-outline-primary open-calculator" onClick={showCalculator}>
                                    <i class="bi bi-question-circle me-2"></i>
                                    {calc_text_trans}
                                </div>
                                : null
                            }
                        </div>
                        { calculator }
                    </CartForm>
                    <div className="btn-wrapper" style={{width:'fit-content'}} >
                        <button onClick={cart_context.addToCart} type="submit" className="u-btn u-btn-submit u-button-style cart-button" ><i className="bi bi-cart me-2"></i> {add_trans}</button>
                    </div>
                </>
            }
        </div>
    )
}

export default ProductForm;