import { useCallback, useEffect, useState } from "react"
import ProductForm from "./ProductForm/ProductForm"
import ProductImages from "./ProductImages/ProductImages"
import Modal from "../../UI/Modal/Modal"
import ModalContext from "../../store/ModalContext"
import CartContext from "../../store/CartContext"
import { getCookie } from "../../functions/getCookie"
import useHttp from "../../hooks/use-http"
import AddedCartModal from "./ProductForm/AddedCartModal"
import { addToCartEvent } from "../../functions/mrktrk"
import i18next from "i18next"

const sendToCart = async (cartLines) => {
    var headers = {}
    headers["X-CSRFToken"] = getCookie('csrftoken');
    headers["X-Requested-With"] = 'XMLHttpRequest';
    headers['Content-Type'] = 'application/json';
    const response = await fetch('/rest/cart/add_cart_store/', {
        method: 'POST',
        body: JSON.stringify( cartLines ),
        headers:headers,
    })
    return response
}

const ProductDetail = (props) => {
    const [product, setProduct] = useState('')
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState(null);

    const [quantity, setQuantity] = useState(1)  
    const [productPrices, setProductPrices] = useState({});  
    const [cartLines, setCartLines] = useState({}); 

    const {error : dataAppendError, sendRequest : sendHttp} = useHttp(() => {})

    var change_product_trans = i18next.t('change_product_trans')

    const addToCart = useCallback( async (event) => {
        event.preventDefault()
        openModal()
        if(cartLines.length === 0 ){
            setModalContent(<h6 className="text-center">{change_product_trans}</h6>)
            return 
        }
        const response = await sendToCart(cartLines)
        setCartLines([])
        if(response.ok){
            const cart_line_data = await response.json()
            const cartModal = <AddedCartModal
                cartLines={cartLines} 
                productPrices={productPrices}
                quantity={quantity} 
                cart_line={cart_line_data.cart_lines}
                closeModal={closeModal}
                product_listing_objects={product.product_listing_objects.filter( (el) => el.product_object.category.variant_place === '2')} 
                addToCart={addToCart} 
                setCartLines={setCartLines} 
                getProductPrice={getProductPrice} 
            />
            setModalContent(cartModal)
            addToCartEvent(cart_line_data.cart_lines, sendHttp)
        }
        else{
            setModalContent(<h6 className="text-center">Er ging iets mis bij het toevoegen van het product aan de winkelwagen</h6>)
        }
    }, [cartLines])

    useEffect( () => {
        setProductPrices( (prev_productPrices) => {return {...prev_productPrices, [product.id]: product.product_prices}})
        if(product){
            product.product_listing_objects.forEach(element => {
                setProductPrices( (prev_productPrices) => {return {...prev_productPrices, [element.id]: element.product_prices}})
            });
        }
    }, [product])

    const getProductPrice =  useCallback( (id, quantity) => {
        if(Object.keys(productPrices).includes(id)){
            const price = productPrices[id].filter( variant => variant.min_order_quantity <= quantity && variant.max_order_quantity >= quantity)
            return price[0].price_in_vat
        }
    }, [productPrices])

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const modalContext = {
        isModalOpen,
        modalContent,
        closeModal,
        openModal,
        setModalContent,
    } 
    const cartContext = {
        cartLines,
        product,
        productPrices,
        quantity,
        setQuantity,
        getProductPrice,
        setCartLines,
        addToCart,
    } 
 
 

    useEffect(() => {  
        let text = document.getElementById('page-context').textContent  
        setProduct(JSON.parse(text))  
    }, [])  

    if(!product.product_object){
        return <div></div>
    }
    return <CartContext.Provider value={cartContext}>
            <ModalContext.Provider value={modalContext}>
                <Modal /> 
                <ProductImages product={product} />
                <ProductForm  product={product} setModalContent={setModalContent} openModal={openModal} closeModal={closeModal}/>
            </ModalContext.Provider>
        </CartContext.Provider>

}


export default ProductDetail