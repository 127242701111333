const Steps = (props) =>{

    var classes = "step "
    if(props.current){
        classes += "current"
    }
    else if(props.fulfilled){
        classes += "fullfilled"
    }
    return (
        <div onClick={ () => { if(props.fulfilled){props.setCurrentStep(props.step)}}} class={classes} >
            <small class="">{props.step.name}</small>
        </div>

    )
}



export default Steps