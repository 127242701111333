import { useEffect, useState } from "react";
import ElementAddToCart from "./ElementAddToCart";

const Quantity = ({parent_cart_line_id, option, setData, formChange }) => {
  const [quantity, setQuantity] = useState(0)  

  useEffect( () => {
    formChange()
  }, [quantity])

  return (
      <div class={`quantity-option ${quantity > 0 ? "selected" : ""}`}>
        {(option.product_object.image_small) ? <img className="variant-image" src={option.product_object.image_small} /> : '' }
        <label class="quantity-description ms-1" for={`"option"${option.id}`}>
            <p class="option-name">{option.product_object.name}</p>
            {(parseFloat(option.price) !== 0) ? <span>+€{option.price}</span> : null}
        </label>
        <div class="btn-group btn-group-sm mt-1 mb-2" role="group" aria-label="Small button group">
          <div 
              onClick={(e) => setQuantity((prev) => (Math.max(prev - 1, 0)))}
              className="py-1 btn btn-outline-dark text-dark d-flex justify-content-center align-items-center" 
              type="button" id="button-plus"> 
              <i className="bi bi-dash"></i> 
          </div>
          <input 
              data-parent_cart_line_id={parent_cart_line_id}
              data-product={option.id}
              data-parent={option.parent_listing}
              data-type="quantity"
              data-price={option.price_in_vat}
              data-discountprice={option.disc_price_in_vat}
              data-quantity={option.quantity}
              className="btn btn-outline-dark d-inline flex-grow-2 pe-0 form-control" 
              type="number"
              name={option.id}
              min={0}
              id={`option${option.id}`}
              onChange={e => setQuantity(parseInt(e.target.value)||0)}
              value={quantity} />
          <div 
              onClick={(e) => setQuantity((prev) => (prev +1))}
              className="py-1 btn btn-outline-dark text-dark d-flex justify-content-center align-items-center" 
              type="button" 
              id="button-minus"> 
              <i className="bi bi-plus"></i> 
          </div>
        </div>
      </div>
  );
};

export default Quantity;
