import { useEffect, useRef, useState } from "react"
import FormElement from "./FormElements/FormElement"
import ProductPrice from "./ProductPrice"
import i18next from '../../../i18n'

const CartForm = (props) => {
    const [productForm, setProductForm] = useState(null)  
    const [totalPrice, setTotalPrice] = useState(0)  
    const [totalDiscountPrice, setTotalDiscountPrice] = useState(0)  
    const formRef = useRef(null)    
    var configure_trans = i18next.t('configure_trans')

    const formChange = (event) => {
        if(props.productPrices){
            if(Object.keys(props.productPrices).length > 0){
                setTotalPrice(0)
                setTotalDiscountPrice(0)
                const form_array = Array.from(formRef.current.elements)
                const chosenVariants = form_array.map( (element) => {
                    if(element.dataset.product){
                        var quantity = (element.dataset.type == 'quantity') ? element.value : Math.ceil(props.quantity * element.dataset.quantity)
                        if (element.dataset.type == 'radio' || element.dataset.type == 'checkbox'){
                            if(!element.checked){
                                return
                            }
                        }
                        if (quantity == 0) {
                            return
                        }
                        setTotalPrice( (value) => {return value + (quantity * parseFloat(element.dataset.price))})
                        var discount_price = props.getProductPrice(element.dataset.product, quantity)
                        setTotalDiscountPrice( (value) => {return value + (quantity * parseFloat(discount_price))})
                        return {[element.dataset.parent + '_' + element.dataset.product] : {
                            parent_line:element.dataset.parent_cart_line_id,
                            product_listing:element.dataset.product,
                            parent_listing:element.dataset.parent,
                            type:element.dataset.type,
                            quantity:quantity,
                            value:element.value,
                        }
                    }}
                })
                props.setCartLines( chosenVariants )
            }
        }
    }


    useEffect( () => {
        if (formRef.current) formChange()
    }, [formRef.current, props.quantity])


    let get_categories = (values) => {
        let unique_values = values
            .map((variant) => variant.category)
            .filter(
                (value, index, current_value) => current_value.indexOf(value) === index
            );
        return unique_values;
    };

    useEffect(() => {
        if(props.product_listing_objects.length > 0){
            var categories = get_categories(props.product_listing_objects)
            var category_variants = categories.map( (category) => {  
                var form_options = props.product_listing_objects.filter((variant) => variant.category === category )
                return { [category] : <FormElement addToCart={props.addToCart} parent_cart_line_id={props.parent_cart_line_id} category={category} form_options={form_options} formChange={formChange} /> }
            })
            var html_elements = []
            category_variants.forEach(element => {
                var key = Object.keys(element)[0]
                html_elements.push(
                    element[key]
                )
            });
            setProductForm(html_elements)
        }
    },[props.product_listing_objects])
    return (
        <div id="configure" class="mt-4">
            
            {(props.showprice) ? 
                <ProductPrice totalPrice={totalPrice} totalDiscountPrice={totalDiscountPrice} {...props} />
                : null
            }

            <form className="product-form" ref={formRef} onChange={formChange} >
                {props.children}
                
                {(productForm) ? 
                    <>  
                        {(props.showprice) ? 
                            <p class="text-center border-bottom">{configure_trans}</p>
                            : null
                        }

                        {productForm}
                    </>
                    :
                    null
                }
            </form>

        </div>
    )
}


export default CartForm