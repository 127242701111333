import dayjs from "dayjs";

export function euroFormatter(value, row, id){
    if(value != null){
        return  "€" + value.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          })
    }
}
export function percentageFormatter(value, row, id){
    if(value != null){
        return  value *100  + "%" 
    }
}

export function booleanFormatter(value, row, id){
    if(value){
        return <i class="bi bi-check-lg text-success"></i>
    } 
    else if (value === false){
        return <i class="bi bi-x-lg text-danger"></i>
    }
    return '-'
}

export function dateFormatter(value, row, id, format = 'DD-MM-YYYY'){
    if(value != null){
        var date = Date.parse(value)
        return dayjs(date).format(format)
    }
}
export function datetimeFormatter(value, row, id, format = 'DD-MM-YYYY HH:mm'){
    if(value != null){
        var date = Date.parse(value)
        return dayjs(date).format(format)
    }
}
