import { euroFormatter } from "../../functions/formatters"

const PriceCell = ({line, cartLineTotal}) => {
    var price = cartLineTotal.price
    var discount_price = cartLineTotal.discount_price

    return(
        <div class="price-wrap"> 
            <h5 class="price text-large mb-1">{ euroFormatter(discount_price)} </h5>
            { (discount_price != price) ? <var class="old-price">{euroFormatter(price)} </var> : '' }
        </div> 
    )
}

export default PriceCell;