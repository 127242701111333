import {createContext} from 'react'


const CartContext = createContext({  
    cartLines : [],
    modalContent : null,
    setCartLines : () => {},
});

export default CartContext;
